import React, { useEffect, useState } from 'react';
import Layout from '../../components/Layout'
import { Chat, Channel, Window, MessageList, MessageInput, LoadingIndicator, ChannelList, ChannelHeader, } from "stream-chat-react";
import "stream-chat-react/dist/css/index.css";
import { StreamChat } from "stream-chat";
import { useDispatch, useSelector } from 'react-redux';
import { chattingUserData } from '../../state/dashboardViewAction';

export default function Messages() {
  const [client, setClient] = useState(null);
  const userDetails = useSelector(state => state.user.userDetail);
  // const [messageCount , setMessageCount]=useState("")
  const apikey = process.env.STREAM_CHAT_APIKEY;
  const filter = { members: { $in: [userDetails._id] } }
  const dispatch=useDispatch()

  const hanldeImage = (img) => {
    let data = img !== null && img.split('/')[0]
    if (data === "uploads") {
      return process.env.API_IMAGE_URL + img
    }
    else {
      return img
    }
  }

  useEffect(() => {
    async function init() {
      let user = {
        id: userDetails._id,
        name: userDetails.name,
        image: hanldeImage(userDetails.img !== null ? userDetails.img : "")
      }
      const chatClient = StreamChat.getInstance(apikey);
      chatClient.on(event => {
        if (event.total_unread_count !== undefined) {
          // setMessageCount(event.total_unread_count)
        }
      });
      await chatClient.connectUser(user, chatClient.devToken(user.id));
      dispatch(chattingUserData(chatClient))
      
      setClient(chatClient);
      
    }
    init();

  });
  if (!client) return <LoadingIndicator />;

  return (
    <Layout>
      <Chat client={client} theme="messaging light">
        <ChannelList
          filters={filter}
        />
        <Channel >
          <Window>
            <ChannelHeader />
            <MessageList />
            <MessageInput />
          </Window>
        </Channel>
      </Chat>
    </Layout>


  )
}



